<p-menubar [baseZIndex]="1" [model]="items" class="menubar">
  <ng-template pTemplate="start">
    <div class="logo-container">
      <img
        src="assets/WatchGuard_Logo.svg"
        alt="logo"
        height="40"
        class="logo"
      />
    </div>
  </ng-template>

  <ng-template pTemplate="end">
    <p-button
      label="Logout"
      icon="pi pi-sign-out"
      severity="danger"
      *ngIf="isLoggedIn"
      [text]="true"
      (click)="logout()"
    ></p-button>
  </ng-template>
</p-menubar>
