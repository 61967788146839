import { Component } from '@angular/core';
import { QUESTION_STRINGS } from '../../constants/app-strings.constants';

@Component({
  selector: 'app-dropdown-header',
  templateUrl: './dropdown-header.component.html',
  styleUrl: './dropdown-header.component.scss',
})
export class DropdownHeaderComponent {
  QUESTION_STRINGS = QUESTION_STRINGS;
}
