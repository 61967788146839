import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  ASSETS,
  AUTH_MESSAGES,
  AUTH_STRINGS,
  ROUTER_LINKS,
} from '../../constants/app-strings.constants';
import { LoginResponse } from '../../models/login-response.model';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent implements OnDestroy {
  @ViewChild('loginForm') form: NgForm | undefined;
  ASSETS = ASSETS;
  AUTH_STRINGS = AUTH_STRINGS;
  AUTH_MESSAGES = AUTH_MESSAGES;
  loading: boolean = false;
  loginSubscription: Subscription | undefined;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router
  ) {}

  login(): void {
    this.loading = true;
    const loginCredentials = this.form?.value;
    this.loginSubscription = this.authService
      .login(loginCredentials)
      .subscribe({
        next: (loginResponse: LoginResponse) => {
          this.loading = false;
          this.messageService.add({
            severity: 'success',
            detail: this.AUTH_MESSAGES.loginSuccessful,
          });
          sessionStorage.setItem(AUTH_STRINGS.idtoken, loginResponse.idtoken);
          this.authService.loginLogoutStatus$.next();
          this.router.navigateByUrl(ROUTER_LINKS.library);
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            detail: this.AUTH_MESSAGES.invalidCredentials,
          });
          this.loading = false;
        },
      });
  }

  ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
  }
}
