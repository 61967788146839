import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TestPayload } from 'src/app/shared/models/test-payload.model';

@Injectable({
  providedIn: 'root',
})
export class TestService {
  @Output() addQuestionToTestPool$ = new EventEmitter<any>();
  @Output() deleteQuestionfromTestPool$ = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {}

  addTest(testData: TestPayload): Observable<any> {
    return this.http
      .post<any>(`${environment.API_URL}/wgassessmentportal/test`, testData)
      .pipe(catchError((error) => this.errorHandlerService.handleError(error)));
  }

  getTestList(year: number): Observable<any> {
    return this.http
      .get<any>(
        `${environment.API_URL}/wgassessmentportal/test/list?year=${year}`
      )
      .pipe(catchError((error) => this.errorHandlerService.handleError(error)));
  }

  getTestbyId(testId: string): Observable<any> {
    return this.http
      .get<any>(`${environment.API_URL}/wgassessmentportal/test/${testId}`)
      .pipe(catchError((error) => this.errorHandlerService.handleError(error)));
  }

  updateTest(testId: string, testPayload: any): Observable<any> {
    return this.http
      .put<any>(`${environment.API_URL}/wgassessmentportal/test/${testId}`, testPayload)
      .pipe(catchError((error) => this.errorHandlerService.handleError(error)));
  }
}
