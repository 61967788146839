<div class="question-card">
  <p-card>
    <div class="question-line-div">
      {{ questionApiResponse?.questionData?.question_text }}
      <div>
        <p-button
          icon="pi pi-pen-to-square"
          severity="danger"
          pTooltip="{{ QUESTION_CARD_STRINGS.updateQuestionTooltip }}"
          tooltipPosition="bottom"
          class="edit"
          [text]="true"
        ></p-button>

        <p-button
          icon="pi pi-trash"
          severity="danger"
          pTooltip="{{ QUESTION_CARD_STRINGS.deleteQuestionTooltip }}"
          tooltipPosition="bottom"
          class="delete"
          [text]="true"
        ></p-button>
      </div>
    </div>

    <p-tag
      severity="success"
      [class]="questionApiResponse?.questionData?.level"
      [rounded]="true"
      [value]="questionApiResponse?.questionData?.level"
      [class]="'tags'"
    ></p-tag>
    <ng-container class="tags">
      <p-tag
        severity="success"
        [value]="questionApiResponse?.questionData?.topic"
        [class]="'tags'"
        [rounded]="true"
      ></p-tag>
    </ng-container>
    <div class="options-list">
      <label
        [ngClass]="{
          'option-bold': questionApiResponse?.answer == OPTIONS.option1
        }"
        >1. {{ questionApiResponse?.questionData?.options?.option1 }}</label
      >
      <label
        [ngClass]="{
          'option-bold': questionApiResponse?.answer == OPTIONS.option2
        }"
        >2. {{ questionApiResponse?.questionData?.options?.option2 }}</label
      >
      <label
        [ngClass]="{
          'option-bold': questionApiResponse?.answer == OPTIONS.option3
        }"
        >3. {{ questionApiResponse?.questionData?.options?.option3 }}</label
      >
      <label
        [ngClass]="{
          'option-bold': questionApiResponse?.answer == OPTIONS.option4
        }"
        >4. {{ questionApiResponse?.questionData?.options?.option4 }}</label
      >
    </div>
  </p-card>
</div>
