import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  sidebarVisible: boolean = true;

  constructor(private router: Router) {}

  isLibraryRoute(): boolean {
    const url = this.router.url;
    if (url.includes('library')) return true;
    return false;
  }

  isTestRoute(): boolean {
    const url = this.router.url;
    if (url.includes('test')) return true;
    return false;
  }

  isTopicsRoute(): boolean {
    const url = this.router.url;
    if (url.includes('topics')) return true;
    return false;
  }

  isQuestionsRoute(): boolean {
    const url = this.router.url;
    if (url.includes('questions')) return true;
    return false;
  }

  isDashboardRoute(): boolean {
    const url = this.router.url;
    if (url.endsWith('test/test-list')) return true;
    return false;
  }
}
