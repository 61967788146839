import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_LINKS } from './shared/constants/app-strings.constants';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthComponent } from './shared/components/auth/auth.component';
import { authGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: ROUTER_LINKS.login, pathMatch: 'full' },
  { path: ROUTER_LINKS.login, component: AuthComponent },
  {
    path: ROUTER_LINKS.library,
    loadChildren: () =>
      import('./modules/library/library.module').then(
        (mod) => mod.LibraryModule
      ),
    canActivate: [authGuard],
  },
  {
    path: ROUTER_LINKS.test,
    loadChildren: () =>
      import('./modules/test/test.module').then(
        (mod) => mod.TestModule),
    canActivate: [authGuard],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
