<div class="question-card">
  <p-card>
    <div class="question-line-div">
      <div class="problem-statement">
        {{index + 1}}. {{ question?.problem_statement }}
      </div>
      <div class="action-btn">
        <p-button
          icon="pi pi-pen-to-square"
          severity="danger"
          pTooltip="{{ QUESTION_CARD_STRINGS.updateQuestionTooltip }}"
          tooltipPosition="bottom"
          class="edit"
          [text]="true"
          (click)="openQuestionForm()"
          *ngIf="mode == MODE.listQuestion"
        ></p-button>

        <p-button
          icon="pi pi-trash"
          severity="danger"
          pTooltip="{{ QUESTION_CARD_STRINGS.deleteQuestionTooltip }}"
          tooltipPosition="bottom"
          class="delete"
          [text]="true"
          (click)="deleteQuestionFromTestPool()"
          *ngIf="mode == MODE.listQuestion || mode == MODE.editTest"
        ></p-button>

        <p-button
          icon="pi pi-plus"
          severity="danger"
          pTooltip="{{ QUESTION_CARD_STRINGS.addQuestionTooltip }}"
          tooltipPosition="bottom"
          class="delete"
          [text]="true"
          (click)="addQuestionToTestPool()"
          *ngIf="mode == MODE.questionPool"
        ></p-button>
      </div>
    </div>

    <ng-container class="tags">
      <p-tag
        severity="success"
        [value]="question?.NODE"
        [class]="'tags'"
        [rounded]="true"
      ></p-tag>
    </ng-container>
    <div class="options-list">
      <label
        [ngClass]="{
          'option-bold': question?.correct_answer == OPTIONS.option1
        }"
        >1. {{ question?.option_1 }}</label
      >
      <label
        [ngClass]="{
          'option-bold': question?.correct_answer == OPTIONS.option2
        }"
        >2. {{ question?.option_2 }}</label
      >
      <label
        [ngClass]="{
          'option-bold': question?.correct_answer == OPTIONS.option3
        }"
        >3. {{ question?.option_3 }}</label
      >
      <label
        [ngClass]="{
          'option-bold': question?.correct_answer == OPTIONS.option4
        }"
        >4. {{ question?.option_4 }}</label
      >
    </div>
  </p-card>
</div>
