<div class="login-page">
  <div class="app-login-form">
    <form (ngSubmit)="login()" #loginForm="ngForm">
      <div class="form-group">
        <p-floatLabel>
          <input
            type="text"
            name="username"
            id="username"
            ngModel
            pInputText
            required
          />
          <label for="username">{{ AUTH_STRINGS.label.username }}</label>
        </p-floatLabel>
      </div>
      <div class="form-group">
        <p-floatLabel>
          <p-password
            name="password"
            feedback="false"
            ngModel
            [toggleMask]="true"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%', height: '3.5rem' }"
          />
          <label for="password">{{ AUTH_STRINGS.label.password }}</label>
        </p-floatLabel>
      </div>
      <div class="form-group">
        <p-button
          type="submit"
          severity="danger"
          [label]="AUTH_STRINGS.label.login"
          [disabled]="!loginForm.valid"
          [loading]="loading"
          [outlined]="true"
        ></p-button>
      </div>
    </form>
  </div>

  <img [src]="ASSETS.wgLion" alt="WG-lion-graphic" />
</div>
