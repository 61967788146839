import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ROUTER_LINKS } from '../../constants/app-strings.constants';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit{
  isLoggedIn: boolean = false;
  role: string = '';
  items: MenuItem[] | undefined;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = this.authService.getLoginStatus();
    this.authService.loginLogoutStatus$.subscribe({
      next: () => {
        this.isLoggedIn = this.authService.getLoginStatus();
        this.updateMenuItems();
      },
    });
    this.updateMenuItems();
  }

  updateMenuItems(): void {
    this.items = [
      {
        label: "Library",
        visible: this.isLoggedIn,
        routerLink: ROUTER_LINKS.library
      },
      {
        label: "Test",
        visible: this.isLoggedIn,
        routerLink: ROUTER_LINKS.test
      }
    ];
  }

  logout(): void {
    this.isLoggedIn = false;
    this.authService.logout();
    this.router.navigateByUrl(ROUTER_LINKS.login);
    this.updateMenuItems();
  }
}
