<p-sidebar
  modal="false"
  dismissible="false"
  showCloseIcon="false"
  closeOnEscape="false"
  [(visible)]="sidebarVisible"
>
  <p
    routerLink="/test/dashboard"
    *ngIf="isTestRoute()"
    [ngStyle]="isDashboardRoute() ? { 'font-weight': 'bold' } : null"
  >
    Dashboard
  </p>
  <p
    routerLink="/library/topics"
    *ngIf="isLibraryRoute()"
    [ngStyle]="isTopicsRoute() ? { 'font-weight': 'bold' } : null"
  >
    Topics
  </p>
  <p
    routerLink="/library/questions"
    *ngIf="isLibraryRoute()"
    [ngStyle]="isQuestionsRoute() ? { 'font-weight': 'bold' } : null"
  >
    Questions
  </p>
</p-sidebar>
