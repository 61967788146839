import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { PasswordModule } from 'primeng/password';
import { SidebarModule } from 'primeng/sidebar';
import { StepperModule } from 'primeng/stepper';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownHeaderComponent } from './components/dropdown-header/dropdown-header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { QuestionCardComponent } from './components/question-card/question-card.component';

@NgModule({
  declarations: [
    QuestionCardComponent,
    DropdownHeaderComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    TagModule,
    ButtonModule,
    CardModule,
    TooltipModule,
    DropdownModule,
  ],
  exports: [
    AccordionModule,
    ConfirmDialogModule,
    ToastModule,
    MenubarModule,
    SidebarModule,
    ButtonModule,
    PasswordModule,
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    TagModule,
    CardModule,
    TooltipModule,
    QuestionCardComponent,
    DropdownHeaderComponent,
    DropdownModule,
    LoaderComponent,
    DialogModule,
    InputTextareaModule,
    TableModule,
    StepperModule,
    CalendarModule,
  ],
})
export class SharedModule {}
