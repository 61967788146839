import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AUTH_STRINGS, ROUTER_LINKS } from 'src/app/shared/constants/app-strings.constants';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);

  if (sessionStorage.getItem(AUTH_STRINGS.idtoken)) {
    return true;
  } else {
    router.navigateByUrl(ROUTER_LINKS.login);
    return false;
  }
};
