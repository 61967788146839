import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AUTH_STRINGS, ERROR_MESSAGES, ROUTER_LINKS } from '../../shared/constants/app-strings.constants';
import { ErrorCode } from 'src/app/shared/enums/error-codes.enumerator';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private router: Router) {}

  handleError(errorResponse: HttpErrorResponse): Observable<never> {
    const errorData = errorResponse.error;
    switch (errorResponse.status) {
      case ErrorCode.BadRequest:
        return throwError(errorData.message);
      case ErrorCode.Unauthorized:
        sessionStorage.removeItem(AUTH_STRINGS.idtoken);
        this.router.navigateByUrl(ROUTER_LINKS.login);
        return throwError(errorData.message);
      case ErrorCode.Forbidden:
        this.router.navigateByUrl(ROUTER_LINKS.login);
        return throwError(errorData.message);
      case ErrorCode.NotFound:
        return throwError(errorData.message);
      case ErrorCode.Conflict:
        return throwError(ERROR_MESSAGES.conflict);
      case ErrorCode.UnprocessableContent:
        return throwError(ERROR_MESSAGES.unprocessableContent);
      case ErrorCode.InternalServerError:
        return throwError(errorData.message);
      default:
        return throwError(ERROR_MESSAGES.genericError);
    }
  }
}
