import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  OPTIONS,
  QUESTION_CARD_STRINGS,
} from '../../constants/app-strings.constants';

@Component({
  selector: 'app-question-card',
  templateUrl: './question-card.component.html',
  styleUrl: './question-card.component.scss',
})
export class QuestionCardComponent {
  @Input() questionApiResponse: any;
  @Output() addQuestionToLibraryEvent = new EventEmitter<any>();
  OPTIONS = OPTIONS;
  QUESTION_CARD_STRINGS = QUESTION_CARD_STRINGS;

  addQuestionToLibrary(): void {
    this.addQuestionToLibraryEvent.emit(this.questionApiResponse);
  }
}
