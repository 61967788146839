export const ROUTER_LINKS = {
  login: 'login',
  library: 'library',
  test: 'test',
};

export const ASSETS = {
  wgLion: 'assets/WG-lion-graphic.png',
};

export const AUTH_STRINGS = {
  label: {
    email: 'Email',
    password: 'Password',
    login: 'Login',
    username: 'Username',
  },
  idtoken: 'idtoken',
};

export const AUTH_MESSAGES = {
  loginSuccessful: 'Logged In Successfully',
  invalidCredentials: 'Invalid Credentials',
  success: 'success',
  logoutSuccess: 'Logged out successfully',
};

export const OPTIONS = {
  option1: 'option1',
  option2: 'option2',
  option3: 'option3',
  option4: 'option4',
};

export const QUESTION_CARD_STRINGS = {
  updateQuestionTooltip: 'Update Question',
  deleteQuestionTooltip: 'Delete Question',
  addQuestionToLibrary: 'Add Question To Library',
};

export const QUESTION_STRINGS = {
  selectTopic: 'Select a topic',
  selectLevel: 'Select a level',
  uploadTooltip: 'Upload Questions via Excel',
  addTooltip: 'Add Question',
  noQuestionsFound: 'No Questions found, Try another topic and level',
  label: {
    createQuestion: 'Create Question',
  },
};

export const TOPIC_CARD_STRINGS = {
  updateTopicTooltip: 'Update Topic',
  deleteTopicTooltip: 'Delete Topic',
};

export const ERROR_MESSAGES = {
  conflict: 'Provide Unique Data',
  unprocessableContent: 'Pattern Validation Failed',
  genericError: 'Some error occured',
};
