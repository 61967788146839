import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  MODE,
  OPTIONS,
  QUESTION_CARD_STRINGS,
} from '../../constants/app-strings.constants';
import { Question } from '../../models/question.model';
import { TestService } from 'src/app/core/services/test.service';

@Component({
  selector: 'app-question-card',
  templateUrl: './question-card.component.html',
  styleUrl: './question-card.component.scss',
})
export class QuestionCardComponent {
  @Input() question: Question | undefined;
  @Input() mode: string | undefined;
  @Input() index: number = 0;
  @Output() addQuestionToLibraryEvent = new EventEmitter<any>();
  @Output() openQuestionForm$ = new EventEmitter<void>();
  OPTIONS = OPTIONS;
  QUESTION_CARD_STRINGS = QUESTION_CARD_STRINGS;
  MODE = MODE;

  constructor(private testService: TestService) {}

  addQuestionToLibrary(): void {
    this.addQuestionToLibraryEvent.emit(this.question);
  }

  addQuestionToTestPool(): void {
    this.testService.addQuestionToTestPool$.emit(this.question);
  }

  deleteQuestionFromTestPool(): void {
    this.testService.deleteQuestionfromTestPool$.emit(this.question);
  }

  openQuestionForm(): void {
    this.openQuestionForm$.emit();
  }
}
