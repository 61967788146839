import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { PasswordModule } from 'primeng/password';
import { SidebarModule } from 'primeng/sidebar';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { QuestionCardComponent } from './components/question-card/question-card.component';
import { DropdownHeaderComponent } from './components/dropdown-header/dropdown-header.component';
import { DropdownModule } from 'primeng/dropdown';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [
    QuestionCardComponent,
    DropdownHeaderComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    TagModule,
    ButtonModule,
    CardModule,
    TooltipModule,
    DropdownModule,
  ],
  exports: [
    ConfirmDialogModule,
    ToastModule,
    MenubarModule,
    SidebarModule,
    ButtonModule,
    PasswordModule,
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    TagModule,
    CardModule,
    TooltipModule,
    QuestionCardComponent,
    DropdownHeaderComponent,
    DropdownModule,
    LoaderComponent,
  ],
})
export class SharedModule {}
