export const ROUTER_LINKS = {
  login: 'login',
  library: 'library',
  test: 'test',
  edit_test: '/test/edit-test',
  questions: 'library/questions',
};

export const ASSETS = {
  wgLion: 'assets/WG-lion-graphic.png',
};

export const AUTH_STRINGS = {
  label: {
    email: 'Email',
    password: 'Password',
    login: 'Login',
    username: 'Username',
  },
  idtoken: 'idtoken',
};

export const AUTH_MESSAGES = {
  loginSuccessful: 'Logged In Successfully',
  invalidCredentials: 'Invalid Credentials',
  success: 'success',
  logoutSuccess: 'Logged out successfully',
};

export const OPTIONS = {
  option1: 'option_1',
  option2: 'option_2',
  option3: 'option_3',
  option4: 'option_4',
};

export const CORRECT_ANSWER = ['option_1', 'option_2', 'option_3', 'option_4'];

export const QUESTION_CARD_STRINGS = {
  updateQuestionTooltip: 'Update Question',
  deleteQuestionTooltip: 'Delete Question',
  addQuestionTooltip: 'Add Question',
  addQuestionToLibrary: 'Add Question To Library',
};

export const QUESTION_STRINGS = {
  selectTopic: 'Select a topic',
  selectLevel: 'Select a level',
  uploadTooltip: 'Upload Questions via Excel',
  addTooltip: 'Add Question',
  noQuestionsFound: 'No Questions found, Try another topic and level',
  label: {
    createQuestion: 'Create Question',
    addQuestion: 'Add Question',
    addCandidate: 'Add a Candidate',
  },
  correctAnswer: 'Correct Answer',
};

export const TOPIC_CARD_STRINGS = {
  updateTopicTooltip: 'Update Topic',
  deleteTopicTooltip: 'Delete Topic',
};

export const ERROR_MESSAGES = {
  conflict: 'Provide Unique Data',
  unprocessableContent: 'Pattern Validation Failed',
  genericError: 'Some error occured',
};

export const MODE = {
  add: 'Add',
  update: 'Update',
  questionPool: 'QuestionPool',
  editTest: 'EditTest',
  listQuestion: 'ListQuestion',
  draftTest: 'DraftTest',
  scheduleTest: 'ScheduleTest',
};

export const TEST_QUESTION_POOL = {
  message: {
    addQuestionSuccessMessage: 'Question Added Successfully',
    success: 'success',
    addQuestionDuplicateMessage: 'Question Already Added',
    failed: 'danger',
  },
  header: {
    addQuestionToPool: 'Add Questions to Pool',
  },
};
