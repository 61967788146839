import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AUTH_STRINGS } from 'src/app/shared/constants/app-strings.constants';
import { environment } from 'src/environments/environment';

export class AuthInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const localEnvironment = window.location.href.indexOf('.com') === -1;
    const serverlessApi = this.isServerlessApi(req.url);

    if (req.headers.has('X-Skip-AuthInterceptor')) {
      const headers = req.headers.delete('X-Skip-AuthInterceptor');
      req = req.clone({ headers });
    } else {
      const idtoken = sessionStorage.getItem(AUTH_STRINGS.idtoken);
      req = req.clone({
        headers: req.headers.append('Authorization', `${idtoken}`),
      });
    }

    if (localEnvironment && serverlessApi) {
      req = this.applyCorsPolicy(req);
    }

    return next.handle(req);
  }

  private isServerlessApi(url: string): boolean {
    if (!url) {
      return false;
    }
    if (/api./.test(url)) {
      return true;
    }
    return false;
  }

  private applyCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {
    if (/api/.test(req.url)) {
      return req.clone({
        url: req.url.replace(environment.API_URL, ''),
      });
    }

    return req;
  }
}
