<p-sidebar
  modal="false"
  dismissible="false"
  showCloseIcon="false"
  closeOnEscape="false"
  [(visible)]="sidebarVisible"
>
  <p
    routerLink="/test/test-list"
    *ngIf="isTestRoute()"
    [ngStyle]="{
      'font-weight': isDashboardRoute() ? 'bold' : 'normal',
      'cursor': 'pointer'
    }"
  >
    Dashboard
  </p>
  <p
    routerLink="/library/topics"
    *ngIf="isLibraryRoute()"
    [ngStyle]="{
      'font-weight': isTopicsRoute() ? 'bold' : 'normal',
      'cursor': 'pointer'
    }"
  >
    Topics
  </p>
  <p
    routerLink="/library/questions"
    *ngIf="isLibraryRoute()"
    [ngStyle]="{
      'font-weight': isQuestionsRoute() ? 'bold' : 'normal',
      'cursor': 'pointer'
    }"
  >
    Questions
  </p>
</p-sidebar>
