import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, Subject, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';
import { AUTH_MESSAGES, AUTH_STRINGS } from 'src/app/shared/constants/app-strings.constants';
import { Login } from 'src/app/shared/models/login.model';
import { LoginResponse } from 'src/app/shared/models/login-response.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loginLogoutStatus$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  login(credentials: Login): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(
        `${environment.API_URL}/wgassessmentlogin/login`,
        credentials,
        {
          headers: new HttpHeaders({ 'X-Skip-AuthInterceptor': '' }),
        }
      )
      .pipe(catchError((error: HttpErrorResponse) => this.errorHandlerService.handleError(error)));
  }

  logout(): void {
    sessionStorage.removeItem(AUTH_STRINGS.idtoken);
    this.messageService.add({
      severity: AUTH_MESSAGES.success,
      detail: AUTH_MESSAGES.logoutSuccess,
    });
    this.loginLogoutStatus$.next();
  }

  getIdToken(): string | null {
    const idtoken = sessionStorage.getItem(AUTH_STRINGS.idtoken);
    return idtoken;
  }

  getLoginStatus(): boolean {
    const idtoken = this.getIdToken();
    if (!idtoken) return false;
    return true;
  }
}
